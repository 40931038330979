import { AnalyticsBrowser } from "@segment/analytics-next";
import posthog from "posthog-js";

const analytics = AnalyticsBrowser.load(
  { writeKey: "hbf4bfqyIUXHcjwrLRApMM3LMa87uDeh" },
  {
    cookie: {
      domain:
        typeof window !== "undefined"
          ? window.location.hostname.includes("composio.dev")
            ? ".composio.dev"
            : window.location.hostname
          : undefined,
    },
    storage: {
      stores: ["cookie", "memory"],
    },
    // Specific Storage Priority
    user: {
      storage: {
        stores: ["cookie", "memory"],
      },
    },
    group: {
      storage: {
        stores: ["cookie", "memory"],
      },
    },
    integrations: {
      "Segment.io": {
        apiHost: "composio_s_1.composio.dev/v1",
        protocol: "https",
      },
    },
  },
);

export class ANALYTICS {
  static track(event: string, properties: Record<string, unknown>) {
    const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

    analytics.track(event, {
      ...properties,
      isDev: IS_DEV,
    });
  }

  static async identify(id: string, properties: Record<string, unknown> = {}) {
    const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

    posthog.identify(id, properties);
    await analytics.identify(
      id,
      {
        ...properties,
        isDev: IS_DEV,
      },
      {},
    );
  }

  static async reset() {
    posthog.reset(true);
    await analytics.reset();
  }

  static page(category: string, name: string, properties: Record<string, unknown>) {
    const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

    analytics.page(category, name, {
      ...properties,
      isDev: IS_DEV,
    });
  }
}
