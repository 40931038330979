import * as React from "react";
import { atom, useAtom } from "jotai";

import { isComposioUser, isDev } from "~/utils/global";

interface FeatureFlags {
  useApollo: {
    enabled: boolean;
    type: "boolean";
    description: string;
  };
}

const featureFlagAtom = atom<FeatureFlags>({
  useApollo: {
    enabled: false,
    type: "boolean",
    description: "Enable Apollo backend",
  },
});

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useAtom(featureFlagAtom);
  // From LaunchDarkly
  // const { useApollo } = useFlags();

  // Check if we're in development or a Composio user to determine if feature flags should be enabled
  const isDevOrComposioUser = isDev() || isComposioUser();

  React.useEffect(() => {
    // In development or for Composio users, use localStorage for feature flags
    if (isDevOrComposioUser) {
      const savedFlags = localStorage.getItem("featureFlags");
      if (savedFlags) {
        try {
          setFeatureFlags(JSON.parse(savedFlags));
        } catch (e) {
          console.error("Failed to parse feature flags from localStorage", e);
        }
      }
    } else {
      // In production for non-Composio users, use LaunchDarkly flags
      // if (useApollo !== undefined) {
      //   updateFeatureFlag("useApollo", useApollo);
      // }
    }
  }, [setFeatureFlags, isDevOrComposioUser]);

  const updateFeatureFlag = (flag: keyof FeatureFlags, enabled: boolean) => {
    setFeatureFlags((prev) => {
      const newFlags = {
        ...prev,
        [flag]: {
          ...prev[flag],
          enabled,
        },
      };
      // Only save to localStorage if we're in development or a Composio user
      if (isDevOrComposioUser) {
        localStorage.setItem("featureFlags", JSON.stringify(newFlags));
      }
      return newFlags;
    });
  };

  return {
    featureFlags: {
      // useApollo: featureFlags?.useApollo?.enabled,
    },
    updateFeatureFlag,
  };
};
export const FeatureFlagBar = () => {
  const { featureFlags, updateFeatureFlag } = useFeatureFlags();
  const isEnabled = isDev() || isComposioUser();
  if (!isEnabled) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center gap-[8px] border-[.5px] border-[#D2D2D2] bg-[#fff] px-[20px] text-[12px] text-[#000]">
      <div className="text-[12px] uppercase text-[#000]">🏴‍☠️ Feature Flags:</div>
      {Object.entries(featureFlagAtom.init).map(([flag, details], index) => (
        <div
          key={flag}
          className={`flex items-center gap-[10px] border-l-[.5px] border-[#D2D2D2] p-[4px] !px-[12px] ${index === Object.keys(featureFlagAtom.init).length - 1 ? "border-r-[.5px]" : ""}`}
        >
          <input
            type="checkbox"
            className="h-[12px] w-[12px] rounded-[2px]"
            checked={featureFlags[flag as keyof typeof featureFlags]}
            onChange={(e) => updateFeatureFlag(flag as keyof FeatureFlags, e.target.checked)}
          />
          <span className="text-[12px] uppercase text-[#000]">{flag}</span>
        </div>
      ))}
    </div>
  );
};
