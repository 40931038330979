import { Button as ButtonShadcn } from "design-system/ui/atoms/button/baseComp";
import { COLORS } from "design-system/ui/base/tokens/colors";
import * as React from "react";
import { LoadingSpinner } from "./loading";

/**
 * ButtonProps defines the properties for the Button component.
 *
 * @property {("40" | "36" | "32" | "44" | "28" | "24")} [size] - The size of the button. Default is "40".
 * @property {React.ReactNode} children - The content to be displayed inside the button.
 * @property {("primary" | "primary_outline" | "secondary" | "secondary_outline" | "destructive" | "disabled" | "link")} [variant] - The variant of the button. Default is "primary".
 * @property {boolean} [uplifted] - If true, the button will have an uplifted style. Default is true.
 * @property {boolean} [isLoading] - If true, a loading spinner will be displayed inside the button. Default is false.
 * @property {string} [loadingText] - The text to be displayed when the button is in loading state. Default is "Loading...".
 */
export type ButtonProps = {
    size?: "40" | "36" | "32" | "44" | "28" | "24";
    children: React.ReactNode;
    variant?: "primary" | "primary_outline" | "secondary" | "secondary_outline" | "destructive" | "disabled" | "link";
    uplifted?: boolean;
    isLoading?: boolean;
    loadingText?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ size = "40", variant = "primary", uplifted = true, isLoading = false, loadingText = undefined, ...props }: ButtonProps, ref) => {
        const isDarkVariant = React.useMemo(() => variant === "primary" || variant === "secondary" || variant === "destructive", [variant]);
        const parsedChild = React.useMemo(
            () =>
                isLoading ? (
                    <span className="flex items-center gap-6">
                        <LoadingSpinner
                            strokeWidth={7}
                            size={Number(size) * 0.5}
                            fill={isDarkVariant ? COLORS.WHITE[50] : COLORS.BLACK[500]}
                        />
                        {loadingText}
                    </span>
                ) : (
                    props.children
                ),
            [isLoading, size, isDarkVariant, props.children],
        );

        const parsedUplifted = React.useMemo(() => (variant === "link" ? false : uplifted), [variant, uplifted]);

        // @ts-ignore
        const parsedDisabled = React.useMemo(
            () => props.disabled || isLoading || variant === "disabled",
            [props.disabled, isLoading, variant],
        );

        return (
            <ButtonShadcn ref={ref} size={size} variant={variant} disabled={parsedDisabled} uplifted={parsedUplifted} {...props}>
                {parsedChild}
            </ButtonShadcn>
        );
    },
);

Button.displayName = "Button";
