/*
    This is for reference only. Use HSL values for colors as it is the most accurate way to define colors across different devices and browsers.

    Figma reference:
    https://www.figma.com/design/vVj8mYIJXZsSmdf3s8jkEW/Design-Library?node-id=13-20766&m=dev
*/
export const COLORS = {
    WHITE: {
        "0": "hsla(0, 0%, 100%, 1)",
        "50": "hsla(0, 0%, 98%, 1)",
        "100": "hsla(0, 0%, 95%, 1)",
        "150": "hsla(0, 0%, 93%, 1)",
        "200": "hsla(0, 0%, 90%, 1)",
        "300": "hsla(0, 0%, 85%, 1)",
        "400": "hsla(0, 0%, 80%, 1)",
        "500": "hsla(0, 0%, 75%, 1)",
        "600": "hsla(0, 0%, 70%, 1)",
        "700": "hsla(0, 0%, 65%, 1)",
        "800": "hsla(0, 0%, 60%, 1)",
        "900": "hsla(0, 0%, 55%, 1)",
        "1000": "hsla(0, 0%, 50%, 1)",
    },
    BLACK: {
        "0": "hsla(0, 0%, 65%, 1)",
        "50": "hsla(0, 0%, 62%, 1)",
        "100": "hsla(0, 0%, 59%, 1)",
        "150": "hsla(0, 0%, 56%, 1)",
        "200": "hsla(0, 0%, 53%, 1)",
        "300": "hsla(0, 0%, 47%, 1)",
        "400": "hsla(0, 0%, 41%, 1)",
        "500": "hsla(0, 0%, 35%, 1)",
        "600": "hsla(0, 0%, 28%, 1)",
        "700": "hsla(0, 0%, 22%, 1)",
        "800": "hsla(0, 0%, 16%, 1)",
        "900": "hsla(0, 0%, 10%, 1)",
        "1000": "hsla(0, 0%, 4%, 1)",
    },
    BLUE: {
        "0": "hsla(212, 100%, 78%, 1)",
        "50": "hsla(212, 98%, 75%, 1)",
        "100": "hsla(212, 97%, 72%, 1)",
        "150": "hsla(212, 95%, 69%, 1)",
        "200": "hsla(212, 93%, 66%, 1)",
        "300": "hsla(212, 89%, 60%, 1)",
        "400": "hsla(212, 85%, 53%, 1)",
        "500": "hsla(212, 94%, 45%, 1)",
        "600": "hsla(212, 100%, 41%, 1)",
        "700": "hsla(212, 100%, 35%, 1)",
        "800": "hsla(212, 100%, 28%, 1)",
        "900": "hsla(212, 100%, 20%, 1)",
        "1000": "hsla(212, 100%, 10%, 1)",
    },

    RED: {
        "0": "hsla(352, 100%, 84%, 1)",
        "50": "hsla(352, 98%, 80%, 1)",
        "100": "hsla(352, 97%, 77%, 1)",
        "150": "hsla(352, 94%, 73%, 1)",
        "200": "hsla(352, 92%, 70%, 1)",
        "300": "hsla(352, 89%, 62%, 1)",
        "400": "hsla(352, 85%, 54%, 1)",
        "500": "hsla(352, 92%, 46%, 1)",
        "600": "hsla(352, 100%, 41%, 1)",
        "700": "hsla(352, 100%, 35%, 1)",
        "800": "hsla(352, 100%, 28%, 1)",
        "900": "hsla(352, 100%, 20%, 1)",
        "1000": "hsla(352, 100%, 10%, 1)",
    },
    ORANGE: {
        "0": "hsla(22, 100%, 82%, 1)",
        "50": "hsla(22, 98%, 78%, 1)",
        "100": "hsla(22, 98%, 75%, 1)",
        "150": "hsla(22, 97%, 71%, 1)",
        "200": "hsla(22, 96%, 67%, 1)",
        "300": "hsla(22, 94%, 59%, 1)",
        "400": "hsla(22, 91%, 51%, 1)",
        "500": "hsla(22, 100%, 46%, 1)",
        "600": "hsla(22, 100%, 44%, 1)",
        "700": "hsla(22, 100%, 41%, 1)",
        "800": "hsla(22, 100%, 37%, 1)",
        "900": "hsla(22, 100%, 32%, 1)",
        "1000": "hsla(22, 100%, 26%, 1)",
    },
    GREEN: {
        "0": "hsla(88, 100%, 85%, 1)",
        "50": "hsla(88, 98%, 81%, 1)",
        "100": "hsla(88, 97%, 77%, 1)",
        "150": "hsla(88, 94%, 73%, 1)",
        "200": "hsla(88, 92%, 69%, 1)",
        "300": "hsla(88, 90%, 61%, 1)",
        "400": "hsla(88, 85%, 53%, 1)",
        "500": "hsla(88, 100%, 44%, 1)",
        "600": "hsla(88, 100%, 41%, 1)",
        "700": "hsla(88, 100%, 36%, 1)",
        "800": "hsla(88, 100%, 29%, 1)",
        "900": "hsla(88, 100%, 20%, 1)",
        "1000": "hsla(88, 100%, 11%, 1)",
    },
    PINK: {
        "0": "hsla(328, 100%, 85%, 1)",
        "50": "hsla(328, 98%, 81%, 1)",
        "100": "hsla(328, 97%, 77%, 1)",
        "150": "hsla(328, 94%, 73%, 1)",
        "200": "hsla(328, 92%, 69%, 1)",
        "300": "hsla(328, 90%, 61%, 1)",
        "400": "hsla(328, 85%, 53%, 1)",
        "500": "hsla(328, 100%, 44%, 1)",
        "600": "hsla(328, 100%, 41%, 1)",
        "700": "hsla(328, 100%, 36%, 1)",
        "800": "hsla(328, 100%, 29%, 1)",
        "900": "hsla(328, 100%, 20%, 1)",
        "1000": "hsla(328, 100%, 11%, 1)",
    },
};
