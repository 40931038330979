import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { css } from "@emotion/css";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60",
  {
    variants: {
      variant: {
        white: "",
        default: "",
        black: "",
      },
      size: {
        24: "h-6 rounded-lg px-3 text-xs tracking-wide",
        28: "h-7 rounded-lg px-3 text-sm font-medium",
        32: "h-8 rounded-[8px] px-3 text-sm tracking-wide",
        36: "h-9 rounded-[8px] px-3 text-sm tracking-wide",
        40: "h-10 rounded-[8px] px-3 text-sm tracking-wide",
        48: "h-12 rounded-[8px] px-3 text-base tracking-wide",
      },
    },
    defaultVariants: {
      variant: "white",
      size: 40,
    },
  },
);

const whiteButtonCSS = css`
  background: rgba(240, 240, 240, 0.21);
  border: 1px solid #cacaca;
  color: #404040;
  box-shadow:
    0px 4px 4px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  transition: all 0.25s ease-in-out;

  &:hover {
    background: rgba(240, 240, 240, 0.3);
  }
`;

const blackButtonCSS = css`
  background: #1e1e1e;
  border: 0.5px solid rgba(143, 143, 143, 0.42);
  color: #ececec;
  box-shadow:
    0px 4px 4px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  transition: all 0.25s ease-in-out;

  &:hover {
    background: #2a2a2a;
  }
`;

const normalButtonCSS = css`
  background: #782bf5;
  border: 0.5px solid #e9d3ff;
  color: #fff;
  box-shadow:
    0px 4px 4px -1px rgba(0, 0, 0, 0.05),
    0px 1px 1px 0px rgba(0, 0, 0, 0.07);
  transition: all 0.25s ease-in-out;

  &:hover {
    background: #6725d8;
  }
`;

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const NewButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = "default", size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const isDisabled = props.disabled;
    return (
      <Comp
        title={isDisabled ? "This button is currently disabled" : ""}
        className={clsx(
          buttonVariants({ variant, size, className }),
          {
            [whiteButtonCSS]: variant === "white",
            [blackButtonCSS]: variant === "black",
            [normalButtonCSS]: variant === "default",
          },
          css`
            cursor: ${isDisabled ? "not-allowed" : "pointer"};
          `,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
NewButton.displayName = "Button";

export { NewButton as Button, buttonVariants };
