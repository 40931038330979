import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";

import { LoadingIcon } from "~/ui/components/base/loadingIcon";

const Portal = ({ children }: { children: React.ReactNode }) => {
  if (typeof window === "undefined") return null;

  const portalRoot = document.getElementById("portal-root");
  if (!portalRoot) return null;

  return ReactDOM.createPortal(children, portalRoot);
};

export const FullScreenLoader = ({
  showLoader,
  message,
  showMessage,
}: {
  showLoader: boolean;
  message?: string;
  showMessage?: boolean;
}) => (
  <AnimatePresence>
    {showLoader && (
      <Portal>
        <div
          className={clsx(
            "fixed left-0  top-0 z-[100000] flex h-[100vh] w-[100vw] items-center justify-center  bg-[white] pb-[100px] text-black-200",
          )}
        >
          <div className="relative z-50 flex flex-col items-center justify-center gap-6">
            <LoadingIcon size={40} />
            <div className="flex flex-col items-center gap-2">
              {showMessage && (
                <>
                  <div className="h-[1px] w-[120px] bg-gray-200"></div>
                  <div className="mt-4 text-base font-medium text-gray-900">{message || "Loading..."}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </Portal>
    )}
  </AnimatePresence>
);
