import * as Sentry from "@sentry/react";
import axios from "axios";

import { client as v1Client } from "~/client";
import { registerV3Interceptors, setBackendConfig } from "~/generated/backendProvider";
import { eraseCookie } from "~/utils/cookie";
import { createCustomError } from "~/utils/error";

export const legacyAxiosClient = axios.create();

// Error handling functions
const handleSentryError = (error: any) => {
  if (error?.response?.status > 400) {
    Sentry.captureException(
      createCustomError(
        "API Handling",
        "🚨 " + " URL: " + error.config.url + " Status: " + error.response.status,
        error,
      ),
      {
        tags: {
          request_id: error?.response?.headers["x-request-id"],
          backend_api_failing: error?.config?.url,
          label: "backend_api_error",
          status: error.response.status,
          statusText: error.response.statusText,
        },
      },
    );
  }
};

const handleUnauthorizedError = (error: any) => {
  const apiUrl = error?.response?.config?.url;
  const isSessionAPIRequest = apiUrl?.endsWith("/session/ping") || apiUrl?.endsWith("/session/ping/");
  const isHomePage = window.location.pathname === "/";
  const isUnauthorized = error?.response?.status === 401;

  if (isUnauthorized && isSessionAPIRequest && !isHomePage) {
    console.error("Unauthorized access detected, redirecting to homepage", {
      url: apiUrl,
      isHomePage,
      isClientInfoRequest: isSessionAPIRequest,
    });
    window.location.href = `/`;
    eraseCookie("isLoggedIn");
    eraseCookie("skipOnboarding");
    eraseCookie("composio_email");
  }
};

// Main error handler that combines all error handling logic
function errorHandler(error: any) {
  handleSentryError(error);
  handleUnauthorizedError(error);
  throw error;
}

// Configure legacy axios client
legacyAxiosClient.defaults.withCredentials = true;
legacyAxiosClient.interceptors.response.use((response) => response, errorHandler);

// Configure v1 client
v1Client.setConfig({
  throwOnError: true,
});
v1Client.instance.defaults.withCredentials = true;
v1Client.instance.interceptors.response.use((response) => response, errorHandler);

// Configure v3 client
registerV3Interceptors({
  onRequest: (config) => {
    config.headers = config.headers || {};

    return config;
  },
  errorHandler,
});

setBackendConfig({
  baseUrl: process.env.NEXT_PUBLIC_V3_API_URL!,
  headers: {
    "x-request-id": crypto.randomUUID(),
  },
});
