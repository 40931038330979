export const getDuration = ({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}): number => {
  return days * 86400 + hours * 3600 + minutes * 60 + seconds;
};

// Base class for Duration
// This is used to create a new Duration object
class DurationBaseClass {
  time: {
    years: number;
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
  };

  constructor() {
    this.time = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    };
  }

  years(years: number) {
    this.time.years += years;
    return this;
  }

  months(months: number) {
    this.time.months += months;
    return this;
  }

  weeks(weeks: number) {
    this.time.weeks += weeks;
    return this;
  }

  days(days: number) {
    this.time.days += days;
    return this;
  }

  hours(hours: number) {
    this.time.hours += hours;
    return this;
  }

  minutes(minutes: number) {
    this.time.minutes += minutes;
    return this;
  }

  seconds(seconds: number) {
    this.time.seconds += seconds;
    return this;
  }

  ms(milliseconds: number) {
    this.time.milliseconds += milliseconds;
    return this;
  }

  toSec() {
    const SECONDS_IN = {
      YEAR: 31536000, // 365 days
      MONTH: 2629746, // ~30.44 days
      WEEK: 604800, // 7 days
      DAY: 86400, // 24 hours
      HOUR: 3600, // 60 minutes
      MINUTE: 60, // 60 seconds
    };

    const totalSeconds =
      this.time.years * SECONDS_IN.YEAR +
      this.time.months * SECONDS_IN.MONTH +
      this.time.weeks * SECONDS_IN.WEEK +
      this.time.days * SECONDS_IN.DAY +
      this.time.hours * SECONDS_IN.HOUR +
      this.time.minutes * SECONDS_IN.MINUTE +
      this.time.seconds;

    return totalSeconds;
  }

  toMS() {
    return this.toSec() * 1000;
  }

  toMinutes() {
    return this.toSec() / 60;
  }
}

export function Duration({
  sec = 0,
  min = 0,
  h = 0,
  d = 0,
  w = 0,
  m = 0,
  y = 0,
  ms = 0,
}: {
  sec?: number;
  min?: number;
  h?: number;
  d?: number;
  w?: number;
  m?: number;
  y?: number;
  ms?: number;
}) {
  return new DurationBaseClass().seconds(sec).minutes(min).hours(h).days(d).weeks(w).months(m).years(y).ms(ms);
}
