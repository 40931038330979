import type { ParsedUrlQuery } from "node:querystring";
import React from "react";
import { useRouter } from "next/router";
import { useQuery } from "@tanstack/react-query";

import { getGlobalTriggerState, getTriggerInstance, getTriggers } from "~/dataProcessor/api/api";
import { useAppInfo } from "~/dataProcessor/hooks/app";

export const QUERY_KEYS = {
  getTriggerDataQueryKey: (query: ParsedUrlQuery) => [
    "TRIGGERS",
    query.page || "1",
    query.name || "",
    query.connectorId || "",
    query.connectionId || "",
    query.showDisabled || "false",
  ],
  getTriggerQueryKey: (appName: string | null, query: ParsedUrlQuery) => ["TRIGGERS", appName, query.page || "1"],
};

export const useTriggerDisabledBanner = () => {
  const { data: triggersInfo, refetch, isLoading } = useQuery(["triggerState"], getGlobalTriggerState, {});
  const [showTriggerDisabledBanner, setShowTriggerDisabledBanner] = React.useState(false);
  const { isNonSidebarPage } = useAppInfo();
  React.useEffect(() => {
    if (triggersInfo && !triggersInfo.triggersEnabled) {
      setShowTriggerDisabledBanner(true);
    } else {
      setShowTriggerDisabledBanner(false);
    }
  }, [triggersInfo?.triggersEnabled]);

  return { showTriggerDisabledBanner: showTriggerDisabledBanner && !isNonSidebarPage, refetch };
};

export const useGetTriggerInstanceData = () => {
  const { query } = useRouter();
  const showDisabled = query.showDisabled === "true";
  const { data, isLoading, isError, isSuccess, refetch } = useQuery({
    queryKey: QUERY_KEYS.getTriggerDataQueryKey(query),
    queryFn: () =>
      getTriggerInstance({
        page: Number(query?.page || 1),
        triggerNames: query.name ? [query.name as string] : undefined,
        connectorId: query.connectorId ? [query.connectorId as string] : undefined,
        connectionId: query.connectionId ? [query.connectionId as string] : undefined,
        showDisabled: showDisabled,
      }),
    retry: false,
    refetchOnWindowFocus: true,
    cacheTime: 0,
    staleTime: 0,
  });

  return {
    triggerData: data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};

export const useGetTriggerData = (appName: string) => {
  const { query } = useRouter();
  const {
    data: triggerData = [],
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: QUERY_KEYS.getTriggerQueryKey(appName as unknown as string, query),
    queryFn: () =>
      getTriggers({
        page: Number(query?.page || 1),
        appName: appName || undefined,
        integrationId: query.connectorId as string | undefined,
        connectedAccountIds: query.connectionId as string | undefined,
      }),
    retry: true,
  });
  return {
    triggerData,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
