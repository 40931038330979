import * as React from "react";
import { css } from "@emotion/css";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { XIcon } from "lucide-react";

import { cn } from "~/utils/cn";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-[200] bg-[#000000d4]  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
      css`
        backdrop-filter: blur(6.5px);
      `,
      className,
    )}
    {...props}
  />
));

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
  // @ts-ignore
>(({ className, overlayClassName, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay className={overlayClassName} />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "sm:rounded-lg class  fixed left-[50%] top-[50%] z-[201] !m-[0] grid w-full max-w-[600px] translate-x-[-50%]  translate-y-[-50%] gap-4 overflow-hidden rounded-[20px] border  bg-white pb-2 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
        className,
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
));

DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="relative">
    <div
      className={cn(
        "sm:text-left flex flex-col space-y-[10px] border-b-[.5px] border-b-[#E0E0E0] bg-[#fafafa] px-6  py-5 text-left tracking-[.35px] ",
        className,
      )}
      {...props}
    />
    {
      <DialogClose
        className="absolute right-[20px] top-[22px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <XIcon className="h-[16px] w-[16px]" />
      </DialogClose>
    }
  </div>
);

DialogHeader.displayName = "DialogHeader";

export const DialogSubtitle = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-[13px] font-[400] leading-none text-black-600">{children}</div>;
};

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "sm:flex-row sm:justify-end sm:space-x-2 flex  justify-end border-t-[.5px] border-t-[#C8C8C8] bg-[#fff] px-[20px] py-[12px]",
      className,
    )}
    {...props}
  />
);

DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn("font-avenir text-[16px] font-[500] leading-none tracking-[.35px]", className)}
    {...props}
  />
));

DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn(
      "text-muted-foreground !mt-[0] p-[20px] text-sm",
      className,
      css`
        margin-top: 0px !important;
      `,
    )}
    {...props}
  />
));

DialogContent.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
};
