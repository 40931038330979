import type { z } from "zod";

export const parseZodWithSentry = (schema: z.Schema, data: unknown) => {
  try {
    return schema.parse(data);
  } catch {
    // Sentry.captureException(e, {
    //     tags: {
    //         url,
    //         issue: "backend_type_mismatch",
    //         internal_label: "backend_type_mismatch",
    //     },
    // });
    return data;
  }
};
