import { css } from "@emotion/css";

export const mobileModeCSS = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  @media (min-width: 1020px) {
    display: none;
  }
`;
