import * as React from "react";

import { cn } from "~/utils/cn";

export const LoadingIconPlain = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number;
    className?: string;
  },
) => {
  const { size = 24, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width={size}
      height={size}
      style={{
        shapeRendering: "auto",
        display: "block",
      }}
      className={cn("block", className)}
      {...props}
    >
      <g>
        <circle
          strokeDasharray="164.93361431346415 56.97787143782138"
          r="35"
          strokeWidth="10"
          stroke="#c6c6c6"
          fill="none"
          cy="50"
          cx="50"
        >
          <animateTransform
            keyTimes="0;1"
            values="0 50 50;360 50 50"
            dur="1s"
            repeatCount="indefinite"
            type="rotate"
            attributeName="transform"
          ></animateTransform>
        </circle>
      </g>
    </svg>
  );
};

export const LoadingIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number;
    className?: string;
  },
) => <LoadingIconPlain {...props} />;
