"use client";

import type { LucideIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { clsx } from "clsx";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "design-system/ui/molecules/sidebar/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "design-system/ui/molecules/sidebar/ui/sidebar";
import { ChevronRight, ExternalLink } from "lucide-react";

export function NavMain({
  items,
  label = "",
  lastNOpened = 2,
}: {
  items: {
    title: string;
    url: string;
    icon?: LucideIcon;
    isPreOpen?: boolean;
    openInNewTab?: boolean;
    items?: {
      title: string;
      url: string;
    }[];
  }[];
  label?: string;
  lastNOpened?: number;
}) {
  // State to track which menu blocks are currently expanded
  const [activeBlocks, setActiveBlocks] = useState<string[]>([]);
  const router = useRouter();
  const isUrlActive = useCallback((url: string) => router.pathname === url, [router.pathname]);

  const isChildOrSelfOpen = useCallback(
    (item: any) => {
      const isActive = isUrlActive(item.url);
      const isChildOpen = item.items?.some((child: any) => isActive || isChildOrSelfOpen(child));
      return isActive || isChildOpen;
    },
    [isUrlActive],
  );

  const handleItemClick = useCallback(
    (title: string) => {
      const currentOpenBlock = items.find((i) => isChildOrSelfOpen(i));
      const currentOpenBlockTitle = currentOpenBlock?.title;

      setActiveBlocks((prevBlocks) => {
        // Remove current open block from list and add it to end if it exists
        const activeBlocksSorted = prevBlocks
          .filter((block) => block !== currentOpenBlockTitle)
          .concat(currentOpenBlockTitle || []);

        if (activeBlocksSorted.includes(title)) {
          if (currentOpenBlockTitle === title) {
            // If block is already open, close it
            return activeBlocksSorted;
          }
          // If block is already open, close it
          return activeBlocksSorted.filter((t) => t !== title);
        }

        // If block is closed, add it while maintaining lastNOpened limit
        return [...activeBlocksSorted, title].slice(-lastNOpened);
      });
    },
    [items],
  );

  // Initialize active blocks on mount and when items change
  useEffect(() => {
    const activeBlocks = items.filter((item) => item.isPreOpen || isChildOrSelfOpen(item)).map((item) => item.title);

    setActiveBlocks(activeBlocks.slice(-lastNOpened));
  }, [items]);

  return (
    <SidebarGroup>
      {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
      <SidebarMenu className="mt-0">
        {items.map((item) => {
          const hasChildren = item.items && item.items.length > 0;
          const isActive = activeBlocks.includes(item.title);
          const openInNewTab = item.openInNewTab ?? false;

          return (
            <Collapsible key={item.title} open={isActive} asChild className="group/collapsible">
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <Link href={item.url} target={openInNewTab ? "_blank" : undefined}>
                    <SidebarMenuButton
                      tooltip={item.title}
                      className={clsx(
                        isChildOrSelfOpen(item) ? "ds-text-blue-700" : "",
                        !hasChildren ? "[&>svg]:size-[13px]" : "[&>svg]:size-[14px]",
                      )}
                      onClick={() => handleItemClick(item.title)}
                    >
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                      {hasChildren ? (
                        <ChevronRight className="ds-ml-auto ds-text-black-700 transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      ) : (
                        <ExternalLink
                          height={10}
                          width={10}
                          className="ds-ml-auto !ds-h-[10px] !ds-w-[10px] ds-text-black-700 transition-transform duration-200"
                        />
                      )}
                    </SidebarMenuButton>
                  </Link>
                </CollapsibleTrigger>

                {hasChildren && (
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton asChild isActive={isUrlActive(subItem.url)}>
                            <Link href={subItem.url} target={openInNewTab ? "_blank" : undefined}>
                              <span>{subItem.title}</span>
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                )}
              </SidebarMenuItem>
            </Collapsible>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}
