import { getCookie } from "./cookie";

export const getCurrentClientSideHost = (): string => window.location.origin;

export const INITIAL_PAGE_AFTER_LOGIN = "/dashboard";

const isProduction = () => {
  return process.env.NODE_ENV === "production";
};

export const isComposioUser = () => {
  const cookie = getCookie("composio_email");
  return cookie?.includes("composio.dev");
};

export const isDev = () => {
  return !isProduction() || !window.location.origin.includes("app.composio.dev");
};
