import type { AxiosRequestConfig, AxiosResponse } from "axios";

import { OpenAPI } from "./backendV3Client";

export * from "./backendV3Client";

OpenAPI.BASE = process.env.NEXT_PUBLIC_V3_API_URL!;
OpenAPI.CREDENTIALS = "include";
OpenAPI.WITH_CREDENTIALS = true;

export const setBackendConfig = ({ baseUrl, headers }: { baseUrl: string; headers: Record<string, string> }) => {
  OpenAPI.BASE = baseUrl;
  OpenAPI.HEADERS = headers;
};

export const registerV3Interceptors = ({
  onRequest,
  onResponse,
  errorHandler,
}: {
  onRequest?: (config: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  onResponse?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
  errorHandler?: (error: any) => any;
}) => {
  if (onRequest) {
    OpenAPI.interceptors.request.use((config) => {
      return onRequest(config);
    });
  } else {
    OpenAPI.interceptors.request.use((config) => {
      return config;
    });
  }

  if (onResponse) {
    OpenAPI.interceptors.response.use((response) => {
      const isError = response.status >= 400;
      if (isError && errorHandler && typeof errorHandler === "function") {
        return errorHandler(response);
      }
      return onResponse(response);
    });
  }
};
