import { z } from "zod";

import type {
  ActionProxyRequestConfigDTO,
  DeleteRowAPIDTO,
  GetConnectorInfoResDTO,
  GetConnectorListResDTO,
} from "~/client/types.gen";
import backend from "~/client/sdk";
import { BACKEND_URL } from "~/utils/baseUrl";
import { parseZodWithSentry } from "~/utils/zod";
import { legacyAxiosClient } from "./global";

export const ZConnectionInfoData = z.object({
  integrationId: z.string(),
  connectionParams: z.object({
    baseURL: z.string().optional(),
    client_id: z.string().optional(),
    client_secret: z.string().optional(),
    headers: z.record(z.string(), z.string()).optional(),
  }),
  id: z.string(),
  labels: z.array(z.string()).optional(),
  statusReason: z.string().optional(),
  status: z.enum(["ACTIVE", "INITIATED", "SUCCESS", "EXPIRED", "FAILED"]),
  createdAt: z.string(),
  updatedAt: z.string(),
  clientUniqueUserId: z.string(),
  enabled: z.boolean().optional(),
  appUniqueId: z.string().optional(),
  meta: z
    .object({
      app: z.object({
        get_current_user_endpoint: z.string().nullish(),
      }),
    })
    .optional(),
});

export const getConnectionInfo = ({ connectionID }: { connectionID: string }) => {
  return backend.connections
    .getConnection({
      path: {
        connectedAccountId: connectionID,
      },
    })
    .then((res) => res.data);
};

export const ZDeleteConnection = z.object({
  status: z.enum(["success", "failed"]),
  count: z.number().optional().nullable(),
});

export const deleteConnection = async ({ connectionID }: { connectionID: string }) => {
  return backend.connections
    .deleteConnection({
      path: {
        connectedAccountId: connectionID,
      },
    })
    .then((res) => res.data);
};

// change isEnable to enable
export const toggleConnectionStatus = ({ connectionID, isEnabled }: { connectionID: string; isEnabled: boolean }) => {
  if (!isEnabled) {
    return backend.connections.disableConnection({
      path: {
        connectedAccountId: connectionID,
      },
    });
  }

  return backend.connections.enableConnection({
    path: {
      connectedAccountId: connectionID,
    },
  });
};

export const getConnectorInfo = ({ connectorId }: { connectorId: string }): Promise<GetConnectorInfoResDTO> => {
  return backend.appConnector
    .getConnectorInfo({
      path: {
        integrationId: connectorId,
      },
    })
    .then((res) => res.data!);
};

export const deleteConnector = async ({ connectorId }: { connectorId: string }) => {
  return backend.appConnector
    .deleteConnector({
      path: {
        integrationId: connectorId,
      },
    })
    .then((res) => res.data)
    .then((data: DeleteRowAPIDTO | undefined) => {
      return data;
    });
};

export const sendProxyConnectionRequest = ({
  endpoint,
  connectedAccountId,
  method,
  parameters,
}: {
  endpoint: string;
  connectedAccountId: string;
  method: ActionProxyRequestConfigDTO["method"];
  parameters: ActionProxyRequestConfigDTO["parameters"];
}) => {
  return backend.actionsV2
    .executeActionProxy({
      query: {
        connectedAccountId,
        endpoint,
      },
      body: {
        method,
        parameters,
      },
    })
    .then((res) => res.data);
};

export const ZIntegrationItemSchema = z.object({
  id: z.string(),
  clientId: z.string().optional(),
  member: z.any(),
  name: z.string(),
  authScheme: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  appId: z.string(),
  appName: z.string().optional(),
  logo: z.string().optional().nullish(),
  enabled: z.boolean().optional(),
  authConfig: z.any().optional(),
  useComposioAuth: z.boolean().optional(),
  _count: z.any().optional(),
});

export const ZConnectionItem = z.object({
  id: z.string(),
  integrationId: z.string().optional(),
  status: z.enum(["ACTIVE", "INITIATED", "SUCCESS"]),
  member: z.any(),
  connectionParams: z
    .object({
      scope: z.string().optional().nullable(),
      base_url: z.string().nullish(),
      client_id: z.string().optional(),
      token_type: z.string().optional(),
      access_token: z.string().optional(),
      client_secret: z.string().optional(),
    })
    .optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  invocationCount: z.string().optional(),
  clientUniqueUserId: z.string().optional().nullable(),
  appName: z.string(),
  isEnabled: z.boolean().optional(),
  isDisabled: z.boolean().optional(),
  logo: z.string().optional(),
  integrationIsDisabled: z.boolean().optional(),
  integrationDisabledReason: z.string().optional(),
});

export const ZConnectionsAPIResponse = z.object({
  items: z.array(ZConnectionItem),
  page: z.number(),
  totalPages: z.number(),
});

export const getClientIntegrations = (
  page = 1,
  appName: string,
  showDisabled: boolean,
): Promise<GetConnectorListResDTO> => {
  return backend.appConnector
    .listAllConnectors({
      query: {
        page,
        appName,
        showDisabled,
        pageSize: 17,
      },
    })
    .then((res) => res.data!);
};

export const ZTriggerInstanceItem = z.object({
  id: z.string(),
  connectionId: z.string(),
  triggerName: z.string(),
  triggerData: z.string().optional(),
  triggerConfig: z.object({
    repo: z.string().optional(),
    owner: z.string(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
  disabledAt: z.string().nullable(),
  connection: z.object({
    id: z.string(),
    integrationId: z.string().optional(),
    status: z.enum(["ACTIVE", "INITIATED", "SUCCESS"]),
    connectionParams: z
      .object({
        scope: z.string().optional().nullable(),
        base_url: z.string().nullish(),
        client_id: z.string().optional(),
        token_type: z.string().optional(),
        access_token: z.string().optional(),
        client_secret: z.string().optional(),
      })
      .optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
    clientUniqueUserId: z.string().optional().nullable(),
    appName: z.string(),
    isEnabled: z.boolean().optional(),
    isDisabled: z.boolean().optional(),
    logo: z.string().optional(),
    integrationIsDisabled: z.boolean().optional(),
    integrationDisabledReason: z.string().optional(),
  }),
});

export const ZTriggerInstanceResponseSchema = z.object({
  triggers: z.array(ZTriggerInstanceItem),
  pageInfo: z.object({
    currentPage: z.number(),
    perPage: z.number(),
    totalPages: z.number(),
  }),
});

export const getTriggerInstance = ({
  triggerNames,
  page,
  connectorId,
  connectionId,
  showDisabled = false,
}: {
  triggerNames?: string[];
  page?: number;
  connectorId?: string[];
  connectionId?: string[];
  showDisabled: boolean;
}) => {
  let queryString = "";
  const params = new URLSearchParams();

  if (triggerNames) params.append("triggerNames", triggerNames.join(","));

  if (page) params.append("page", page.toString());

  if (connectorId) params.append("integrationIds", connectorId.join(","));

  if (connectionId) params.append("connectedAccountIds", connectionId.join(","));

  if (showDisabled) {
    params.append("showDisabled", "true");
  } else {
    params.append("showDisabled", "false");
  }
  params.append("limit", "20");

  queryString = params.toString();

  const url = `${BACKEND_URL}/api/v1/triggers/active_triggers?${queryString}`;

  return legacyAxiosClient
    .get(url, {
      withCredentials: true,
    })
    .then((res) => parseZodWithSentry(ZTriggerInstanceResponseSchema, res.data));
};

export const changeTriggerStatus = ({
  triggerInstanceId,
  enabled,
}: {
  triggerInstanceId: string;
  enabled: boolean;
}) => {
  const url = `${BACKEND_URL}/api/v1/triggers/instance/${triggerInstanceId}/status`;

  return legacyAxiosClient
    .post(url, {
      enabled: enabled,
    })
    .then((res) => res.data);
};

export const getConnections = ({
  connectorId,
  page,
  appName,
  status,
  showDisabled = true,
  user_uuid,
}: {
  connectorId?: string;
  page?: number;
  appName?: string;
  status?: string;
  showDisabled?: boolean;
  user_uuid?: string;
}) => {
  const queryObject = new URLSearchParams({
    ...(connectorId && {
      integrationId: connectorId,
    }),
    ...(!!page && {
      page: page.toString(),
    }),
    ...(!!appName && {
      appNames: appName,
    }),
    ...(!!status && {
      status: status.toString(),
    }),
    ...{
      showDisabled: showDisabled.toString(),
    },
    ...(user_uuid && {
      user_uuid: user_uuid,
    }),
  });

  const queryString = queryObject.toString();
  const url = `${BACKEND_URL}/api/v1/connectedAccounts?${queryString}`;

  return legacyAxiosClient
    .get(url, {
      withCredentials: true,
    })
    .then((res) => parseZodWithSentry(ZConnectionsAPIResponse, res.data));
};

export const getConnectorIntegrationInfo = ({ connectorId }: { connectorId: string }) => {
  return backend.appConnector
    .getConnectorInfo({
      path: {
        integrationId: connectorId,
      },
    })
    .then((res) => res.data);
};

export const ZActionItem = z.object({
  name: z.string(),
  display_name: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  enabled: z.boolean().nullable().optional(),
  parameters: z
    .object({
      properties: z.record(z.any().optional()),
      required: z.array(z.string()).optional(),
      title: z.string(),
      type: z.string(),
    })
    .optional(),
  response: z
    .object({
      properties: z.any(),
      required: z.array(z.string()).optional(),
      title: z.string(),
      type: z.string(),
    })
    .optional(),
  logo: z.string().optional().nullish(),
  appKey: z.string(),
  appId: z.string(),
  appName: z.string(),
  enum: z.string(),
  type: z.string().optional(),
  tags: z.array(z.string()).optional(),
});

export const ZTriggerItem = z.object({
  name: z.string(),
  display_name: z.string(),
  description: z.string().nullable().optional(),
  enabled: z.boolean().nullable().optional(),
  config: z.any().optional(),
  payload: z.any().optional(),
  logo: z.string().optional(),
  count: z.number().optional(),
  appKey: z.string(),
  appId: z.string(),
  appName: z.string(),
  enum: z.string(),
  instructions: z.string().optional(),
  type: z.string().optional(),
});

export const getActions = ({
  appName,
  usecase,
  page = 1,
  limit,
  sortBy,
}: {
  appName?: string;
  usecase?: string;
  page?: number;
  limit?: number | undefined;
  sortBy?: "usage" | "alphabetically";
}) => {
  return backend.actionsV2
    .listActionsMinimalV2({
      query: {
        apps: appName,
        useCase: usecase,
        page,
        // @ts-ignore
        sortBy,
        limit,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      return {
        ...data,
        items: data?.items.filter(({ deprecated }) => !deprecated),
      };
    });
};

const ZGetTriggersResponse = z.array(ZTriggerItem);

export const getTriggers = ({
  appName,
  page = 1,
  integrationId,
  connectedAccountIds,
}: {
  appName?: string;
  page?: number;
  integrationId?: string;
  connectedAccountIds?: string;
}) => {
  const queryParams = new URLSearchParams();
  if (appName) queryParams.append("appNames", appName);
  if (page) queryParams.append("page", page.toString());
  if (integrationId) queryParams.append("integrationIds", integrationId);
  if (connectedAccountIds) queryParams.append("connectedAccountIds", connectedAccountIds);
  const url = `${BACKEND_URL}/api/v1/triggers?${queryParams.toString()}`;

  return legacyAxiosClient
    .get(url, {
      withCredentials: true,
    })
    .then((res) => parseZodWithSentry(ZGetTriggersResponse, res.data));
};

export const ZConnectionInfo = z.object({
  id: z.string(),
  integrationId: z.string(),
  status: z.enum(["ACTIVE"]),
  logo: z.string().optional().nullish(),
  appName: z.string().optional(),
  data: z.object({
    scope: z.string().optional(),
    base_url: z.string().optional(),
    client_id: z.string().optional(),
    token_type: z.enum(["bearer", "Bearer", "bot"]).nullable().optional(),
    access_token: z.string().optional(),
    client_secret: z.string().optional(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const createConnector = (appKey: string, data: Record<string, unknown>) => {
  const url = `${BACKEND_URL}/api/v1/app/${appKey}/connect`;

  return legacyAxiosClient
    .post(url, data, {
      withCredentials: true,
    })
    .then((res) => res.data);
};

export const ZTriggerWebhookURL = z.object({
  status: z.enum(["success", "webhook_url_not_found"]),
  callbackURL: z.string().optional().nullable(),
});

export const getTriggerWebHookURL = (): Promise<z.infer<typeof ZTriggerWebhookURL>> => {
  return backend.triggers
    .getWebhookUrl({
      withCredentials: true,
    })
    .then((res) => parseZodWithSentry(ZTriggerWebhookURL, res.data));
};

export const setTriggerWebHookURL = (webhookURL: string) => {
  const url = `${BACKEND_URL}/api/v1/triggers/set_callback_url`;

  return legacyAxiosClient
    .post(url, {
      callbackURL: webhookURL,
    })
    .then((res) => res.data);
};

export const getEventWebHookURL = (): Promise<Record<string, unknown>> => {
  const url = `${BACKEND_URL}/api/v1/event_logs/get/webhook`;

  return legacyAxiosClient
    .get(url, {
      withCredentials: true,
    })
    .then((res) => res.data);
};

export const setEventWebHookURL = (eventWebhookURL: string) => {
  const url = `${BACKEND_URL}/api/v1/event_logs/set/webhook`;

  return legacyAxiosClient
    .post(url, {
      eventWebhookURL: eventWebhookURL,
    })
    .then((res) => res.data);
};

export const ZDeleteTrigger = z.object({
  status: z.enum(["success", "failed"]),
  count: z.number().optional().nullable(),
});

export const deleteTriggerInstance = (triggerInstanceId: string) => {
  const url = `${BACKEND_URL}/api/v1/triggers/instance/${triggerInstanceId}`;

  return legacyAxiosClient.delete(url).then((res) => parseZodWithSentry(ZDeleteTrigger, res.data));
};

export const ZCliAuth = z.object({
  cliCode: z.string(),
});

export const getCLICodeInExchangeForApp = ({ key }: { key: string }) => {
  const url = `${BACKEND_URL}/api/v1/cli/get-cli-code?key=${key}`;

  return legacyAxiosClient.get(url).then((res) => parseZodWithSentry(ZCliAuth, res.data));
};

export const logoutUser = () => {
  const url = `${BACKEND_URL}/api/v1/client/auth/logout`;
  return legacyAxiosClient.post(url);
};

export const ZToggleTriggerStateResponse = z.object({
  message: z.string(),
});

export const toggleTriggerEnabled = (enabled: boolean): Promise<z.infer<typeof ZToggleTriggerStateResponse>> => {
  const url = `${BACKEND_URL}/api/v1/metadata/toggle/trigger`;

  return legacyAxiosClient
    .post(url, {
      enabled: enabled,
    })
    .then((res) => parseZodWithSentry(ZToggleTriggerStateResponse, res.data));
};

export const ZToggleInfoResponse = z.object({
  triggersEnabled: z.boolean(),
});

export const getGlobalTriggerState = (): Promise<z.infer<typeof ZToggleInfoResponse>> => {
  const url = `${BACKEND_URL}/api/v1/metadata/toggle.info`;

  return legacyAxiosClient.get(url).then((res) => parseZodWithSentry(ZToggleInfoResponse, res.data));
};

export const ZPostGoogleLoginResponse = z.object({
  clientId: z.string(),
  apiKey: z.string(),
  email: z.string(),
});

export const postGoogleLogin = (
  data: {
    jwt?: string;
    authCode?: string;
  },
  type: "auth-code" | "jwt",
): Promise<z.infer<typeof ZPostGoogleLoginResponse>> => {
  const url = `${BACKEND_URL}/api/v1/client/auth/google_login`;

  return legacyAxiosClient
    .post(url, {
      data,
      type,
    })
    .then((res) => parseZodWithSentry(ZPostGoogleLoginResponse, res.data));
};

export const ZSingleTriggerResDTO = z.object({
  name: z.string(),
  displayName: z.string(),
  description: z.string(),
  type: z.string(),
  enum: z.string(),
  appId: z.string(),
  appName: z.string(),
  instructions: z.string().optional(),
  payload: z.record(z.unknown()),
  config: z.record(z.unknown()),
});

export const getTriggerInfo = (triggerName: string): Promise<z.infer<typeof ZSingleTriggerResDTO>> => {
  const url = `${BACKEND_URL}/api/v2/triggers/${triggerName}`;

  return legacyAxiosClient.get(url).then((res) => parseZodWithSentry(ZSingleTriggerResDTO, res.data));
};

export const ZGetActionResDTO = z.array(
  z.object({
    name: z.string().optional(),
    logo: z.string().optional(),
    enabled: z.boolean().optional(),
    enum: z.string().optional(),
    appId: z.string().optional(),
    appName: z.string().optional(),
    appKey: z.string().optional(),
    displayName: z.string().optional(),
    description: z.string().optional(),
    tags: z.array(z.string()).optional(),
    parameters: z.record(z.unknown()).optional(),
    response: z.record(z.unknown()).optional(),
  }),
);

export const getActionInfo = async (actionName: string): Promise<z.infer<typeof ZGetActionResDTO>[0]> => {
  const url = `${BACKEND_URL}/api/v1/actions/${actionName}`;

  return legacyAxiosClient
    .get(url)
    .then((res) => parseZodWithSentry(ZGetActionResDTO, res.data))
    .then((res) => {
      if (!res[0]) {
        throw new Error("No such action found");
      }

      return res[0];
    });
};

export const createCheckoutSession = (payload: { plan: string; applyCoupon?: boolean }) => {
  const url = `${BACKEND_URL}/api/v1/payment/create-checkout-session`;
  return legacyAxiosClient.post(url, payload);
};

export const getCheckoutSessionStatus = (sessionId: string) => {
  const url = `${BACKEND_URL}/api/v1/payment/checkout-session/${sessionId}/status`;
  return legacyAxiosClient.get(url);
};

export const getInvoices = () => {
  const url = `${BACKEND_URL}/api/v1/payment/invoices`;
  const response = legacyAxiosClient.get(url);

  return response.then((res) => res.data);
};

export const createSelfServePortalSession = () => {
  const url = `${BACKEND_URL}/api/v1/payment/self-serve-portal`;
  return legacyAxiosClient.get(url).then((res) => res.data);
};

export const getInvoiceUrl = (invoiceId: string) => {
  const url = `${BACKEND_URL}/api/v1/payment/invoices/${invoiceId}`;
  return legacyAxiosClient.get(url);
};

export const getClientInfo = () => {
  return backend.clientAuthService.getUserInfo();
};

export const getTopEntities = (query = "") => {
  return backend.analytics
    .getTopEntities({
      query: {
        query,
      },
    })
    .then((res) => res.data);
};

export const getExampleActionInput = (actionName: string, input: string) => {
  return legacyAxiosClient
    .post(`${BACKEND_URL}/api/v2/actions/${actionName}/execute/get.inputs`, {
      text: input,
    })
    .then((res) => res.data);
};

export const updateIsNewWebhook = ({ id, isNewWebhook }: { id: string; isNewWebhook: boolean }) => {
  return legacyAxiosClient
    .post(`${BACKEND_URL}/api/v1/triggers/update_webhook_format`, {
      id,
      isNewWebhook,
    })
    .then((res) => res.data);
};
