export enum LOCAL_STORAGE_KEYS {
  APP_SORT_BY = "app.sortBy",
  CURRENT_PROJECT = "current.project",
}

export const storage = {
  get: (key: LOCAL_STORAGE_KEYS, defaultValue: string | null): string | null => {
    try {
      return window.localStorage.getItem(key) || defaultValue;
    } catch (error) {
      console.error(`Failed to retrieve item from localStorage for key "${key}". Returning default value.`, error);
      return defaultValue;
    }
  },
  set: (key: LOCAL_STORAGE_KEYS, value: string): void => {
    window.localStorage.setItem(key, value);
  },
  clearAll: (): void => {
    window.localStorage.clear();
  },
};
