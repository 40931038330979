export const validateURL = (url: string) => {
  try {
    // Check if URL is empty or just whitespace
    if (!url || url.trim() === "") {
      return false;
    }

    const parsedUrl = new URL(url);

    // Allow localhost URLs
    if (url.includes("localhost") || url.includes("127.0.0.1")) {
      return false;
    }

    // Must have http/https protocol
    if (!url.includes("http") && !url.includes("https")) {
      return false;
    }

    // Check for valid TLD by ensuring hostname has at least one dot and characters after it
    const hostnameParts = parsedUrl.hostname.split(".");

    if (hostnameParts.length < 2 || !hostnameParts.at(-1)) {
      return false;
    }

    // Check that hostname parts are valid
    for (const part of hostnameParts) {
      if (!part || /[^\dA-Za-z-]/.test(part)) {
        return false;
      }
    }

    // Check URL length is reasonable
    if (url.length > 2048) {
      return false;
    }

    // Check that port number is valid if specified
    if (parsedUrl.port && (parseInt(parsedUrl.port) <= 0 || parseInt(parsedUrl.port) > 65535)) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export const openInNewTab = (url: string) => {
  // Add noopener and noreferrer for security
  window.open(url, "_blank", "noopener,noreferrer");
};
