import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ChevronRight } from "lucide-react";
import * as React from "react";

import { cn } from "design-system/shadcn/lib/utils";

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuGroup = DropdownMenuPrimitive.Group;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuSubTrigger = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
        inset?: boolean;
    }
>(({ className, inset, children, ...props }, ref) => (
    <DropdownMenuPrimitive.SubTrigger
        ref={ref}
        className={cn(
            "ds-flex ds-cursor-default ds-gap-8 ds-select-none ds-items-center ds-rounded-none ds-px-12 ds-py-6 ds-text-14 ds-outline-none focus:ds-bg-zinc-100 ds-data-[state=open]:bg-zinc-100 ds-[&_svg]:pointer-events-none ds-[&_svg]:size-[12px] ds-[&_svg]:shrink-0",
            inset && "ds-pl-8",
            className,
        )}
        {...props}
    >
        {children}
        <ChevronRight className="ds-ml-auto" />
    </DropdownMenuPrimitive.SubTrigger>
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.SubContent
        ref={ref}
        className={cn(
            "ds-z-50 ds-min-w-[48px] ds-overflow-hidden ds-rounded-[16px] ds-border ds-bg-white ds-text-zinc-950  ds-data-[state=open]:animate-in ds-data-[state=closed]:animate-out ds-data-[state=closed]:fade-out-0 ds-data-[state=open]:fade-in-0 ds-data-[state=closed]:zoom-out-95 ds-data-[state=open]:zoom-in-95 ds-data-[side=bottom]:slide-in-from-top-8 ds-data-[side=left]:slide-in-from-right-2 ds-data-[side=right]:slide-in-from-left-2 ds-data-[side=top]:slide-in-from-bottom-2",
            className,
        )}
        {...props}
    />
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

const DropdownMenuContent = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
            ref={ref}
            sideOffset={sideOffset}
            className={cn(
                "ds-z-[1000] ds-min-w-[212px] ds-bg-white-0 ds-border-white-200 ds-border-1  ds-overflow-hidden ds-rounded-[8px] ds-border  ds-bg-white ds-text-black-900 ds-data-[state=open]:animate-in ds-data-[state=closed]:animate-out ds-data-[state=closed]:fade-out-0 ds-data-[state=open]:fade-in-0 ds-data-[state=closed]:zoom-out-95 ds-data-[state=open]:zoom-in-95 ds-data-[side=bottom]:slide-in-from-top-8 ds-data-[side=left]:slide-in-from-right-8 ds-data-[side=right]:slide-in-from-left-8 ds-data-[side=top]:slide-in-from-bottom-8",
                className,
            )}
            {...props}
        />
    </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
        inset?: boolean;
    }
>(({ className, inset, ...props }, ref) => (
    <DropdownMenuPrimitive.Item
        ref={ref}
        className={cn(
            "ds-relative ds-text-13 hover:ds-bg-white-100 ds-flex ds-cursor-default ds-select-none ds-items-center ds-gap-8 ds-rounded-none ds-px-12 ds-py-6  ds-outline-none ds-transition-colors ds-text-black-1000 focus:ds-bg-zinc-100 focus:ds-text-zinc-900 ds-data-[disabled]:pointer-events-none ds-data-[disabled]:opacity-50 ds-[&_svg]:pointer-events-none  dark:focus:ds-bg-zinc-800 dark:focus:ds-text-zinc-50",
            inset && "ds-pl-8",
            className,
        )}
        {...props}
    />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuLabel = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
        inset?: boolean;
    }
>(({ className, inset, ...props }, ref) => (
    <DropdownMenuPrimitive.Label
        ref={ref}
        className={cn("ds-px-12 ds-my-8 ds-mb-6 ds-text-13 ds-font-00 !ds-text-black-200", inset && "ds-pl-8", className)}
        {...props}
    />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Separator ref={ref} className={cn(" !ds-border-white-200 ds-my-1 ds-border-b-1 ", className)} {...props} />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenuShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
    return <span className={cn("ds-ml-auto !ds-text-10 ds-tracking-widest ds-opacity-60", className)} {...props} />;
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

export {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
};
