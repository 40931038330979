import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";

import { getCookie } from "~/utils/cookie";
import { ANALYTICS } from "~/utils/tracking";

export const useCliKey = () => {
  const { query } = useRouter();

  useEffect(() => {
    if (query.cliKey) {
      const now = new Date();
      const item = {
        value: query.cliKey as string,
        expiry: now.getTime() + 3600000,
      };

      localStorage.setItem("cliKey", JSON.stringify(item));
    }
  }, [query.cliKey]);
};

export const useAppInfo = () => {
  const router = useRouter();
  const isRootPath = router.pathname === "/";

  const isMobileMode = useMemo(() => {
    const nonMobilePaths = ["/", "/google_signin", "/redirect", "/link/incoming"];

    return !nonMobilePaths.includes(router.pathname) && !router.pathname.includes("onboarding");
  }, [router.pathname]);

  const isSEOPath = useMemo(() => {
    const seoPaths = ["/demo"];
    return seoPaths.includes(router.pathname);
  }, [router.pathname]);

  const isNonSidebarPage = useMemo(() => {
    const nonSidebarPaths = [
      "/",
      "/google_signin",
      "/redirect",
      "/demo",
      "/provider_login/[app]",
      "/verify",
      "/onboarding",
      "/link/incoming",
      "/_error",
      "/404",
      "/paymentVerify",
    ];

    return nonSidebarPaths.includes(router.pathname);
  }, [router.pathname]);

  return {
    isRootPath,
    isMobileMode,
    isSEOPath,
    isNonSidebarPage,
  };
};

export const useAppRouterAnalytics = () => {
  const router = useRouter();

  useEffect(() => {
    const currerntURL = window.location.href;
    // get # from url, use split to get the last part
    const hash = currerntURL.split("#").pop();

    setTimeout(() => {
      ANALYTICS.page("app_page", router.pathname, {
        title: document.title,
        url: document.location.href,
        search: router.query,
        hash: hash,
      });
    }, 1000);
  }, [router.pathname]);

  useEffect(() => {
    (async () => {
      const email = getCookie("composio_email");

      if (email) {
        const checkPlausible = setInterval(() => {
          if (window?.plausible) {
            window?.plausible("CLIENT_LOGIN");
            clearInterval(checkPlausible);
          }
        }, 100);

        ANALYTICS.identify(email, {
          email,
        });
      }
    })();
  }, []);
};
