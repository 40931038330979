import * as SDKClient from "./services.gen";

export default {
  logs: SDKClient.LogsService,
  actionsV1: SDKClient.ActionsService,
  actionsV2: SDKClient.ActionsService,
  actionsV2Logs: SDKClient.ActionV2LogsService,
  apiKeys: SDKClient.ApiKeysService,
  clientAuthService: SDKClient.ClientService,
  loginService: SDKClient.LoginService,
  appConnector: SDKClient.IntegrationsService,

  app: SDKClient.AppsService,
  appLogin: SDKClient.ClientService,

  connections: SDKClient.ConnectionsService,
  metadata: SDKClient.MetadataService,
  team: SDKClient.TeamService,
  triggers: SDKClient.TriggersService,
  analytics: SDKClient.AnalyticsService,
  customApps: SDKClient.CustomAppsService,
  eventLogs: SDKClient.EventLogsService,
  mcp: SDKClient.McpService,
};
