import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { X } from "lucide-react";
import * as React from "react";

import { cn } from "design-system/shadcn/lib/utils";

const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Overlay
        className={cn(
            "ds-fixed ds-inset-0 ds-z-[100] ds-bg-black-1000/60 ds-backdrop-blur-[8px] ds-transition-transform ds-transform ds-translate-x-full data-[state=open]:ds-translate-x-0 data-[state=closed]:ds-translate-x-full",
            className,
        )}
        {...props}
        ref={ref}
    />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
    "ds-fixed ds-z-[150] ds-bg-white-50 ds-gap-4 ds-bg-white ds-p-6 ds-transition ds-transform ds-translate-x-full data-[state=open]:ds-translate-x-0 data-[state=closed]:ds-translate-x-full data-[state=open]:ds-animate-in data-[state=closed]:ds-animate-out data-[state=open]:ds-duration-300 data-[state=closed]:ds-duration-200",
    {
        variants: {
            side: {
                right: "ds-inset-y-0 ds-right-0 ds-h-full ds-border-l sm:ds-max-w-sm  data-[state=closed]:ds-slide-out-to-right data-[state=open]:ds-slide-in-from-right",
            },
        },
        defaultVariants: {
            side: "right",
        },
    },
);

interface SheetContentProps extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>, VariantProps<typeof sheetVariants> {
    closeIconClassName?: string;
}

const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
    ({ side = "right", className, children, closeIconClassName, ...props }, ref) => (
        <SheetPortal>
            <SheetOverlay />
            <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({ side }), className)} {...props}>
                {children}
                <SheetPrimitive.Close
                    id="close-button"
                    className={cn(
                        "ds-absolute ds-right-16 ds-top-16 ds-rounded-sm ds-opacity-70 ds-ring-offset-white ds-transition-opacity hover:ds-opacity-100  disabled:ds-pointer-events-none data-[state=open]:ds-bg-zinc-100",
                        closeIconClassName,
                    )}
                >
                    <X className="ds-h-[20px] ds-w-[20px]" />
                    <span className="ds-sr-only">Close</span>
                </SheetPrimitive.Close>
            </SheetPrimitive.Content>
        </SheetPortal>
    ),
);
SheetContent.displayName = SheetPrimitive.Content.displayName;

export { Sheet, SheetClose, SheetContent, SheetOverlay, SheetPortal, SheetTrigger };
