import { useEffect, useState } from "react";
import posthog from "posthog-js";

export const useHasPostHogLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Ensure flags are loaded before usage.
    // You'll only need to call this on the code for when the first time a user visits.
    posthog.onFeatureFlags(() => {
      setIsLoaded(true);
    });

    setTimeout(() => {
      setIsLoaded(true);
    }, 3000);
  }, []);
  return isLoaded;
};

export const usePHFeatureFlag = ({
  key,
  defaultValue = {
    isEnabled: false,
    payload: null,
  },
}: {
  key: string;
  defaultValue: {
    isEnabled: boolean;
    payload: Record<string, unknown> | null;
  };
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    // If in development environment, always return true
    if (process.env.NODE_ENV === "development") {
      setValue({
        isEnabled: true,
        payload: defaultValue.payload,
      });
      return;
    }

    // Ensure flags are loaded before usage.
    // You'll only need to call this on the code for when the first time a user visits.
    posthog.onFeatureFlags(() => {
      // feature flags should be available at this point
      if (posthog.isFeatureEnabled(key)) {
        // do something
        setValue({
          isEnabled: true,
          payload: posthog.getFeatureFlagPayload(key) as Record<string, unknown>,
        });
      }
    });
  }, [key]);

  return value;
};

export async function checkFeatureFlag(flagName: string) {
  const posthogReady = new Promise((resolve) => {
    posthog.onFeatureFlags(() => resolve(true));
  });

  await posthogReady;

  return posthog.isFeatureEnabled(flagName);
}