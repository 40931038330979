export const setCookie = (name: string, value: string, days: number) => {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: string) => {
  // check if window is undefined
  if (typeof window === "undefined") return null;

  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca?.length; i++) {
    let c = ca[i]!;
    while (c.startsWith(" ")) c = c.substring(1, c?.length);

    if (c.startsWith(nameEQ)) return c.substring(nameEQ?.length, c?.length);
  }

  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};
