export const LoadingSpinner = ({ size = 24, fill = "#000", strokeWidth = 6, duration = 1 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: "auto", background: "none", display: "block" }}
            width={size}
            height={size}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            aria-label="Loading spinner"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={fill}
                strokeWidth={strokeWidth}
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur={duration}
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    begin="0s"
                />
            </circle>
        </svg>
    );
};
