import React from "react";
import {
  SidebarContent,
  Sidebar as SidebarDS,
  SidebarFooter,
  SidebarHeader,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
} from "design-system/ui/molecules/sidebar/ui/sidebar";
import { usePHFeatureFlag } from "~/dataProcessor/hooks/global/posthog";
import { AppWindow, BookIcon, Bug, Home, Settings2, UserCheck } from "lucide-react";

import { useUser } from "~/dataProcessor/hooks/user";
import { NavMain } from "./components/menu";
import { ProjectSwitcher } from "./components/projects";
import { NavUser } from "./components/user";

const checkIfComposioUser = (email: string | undefined | null) => {
  return email?.includes("composio.dev") || false;
};
export const useMenu = () => {
  const userInfo = useUser();
  const isInternalUser = checkIfComposioUser(userInfo?.userEmail || "");
  const isNewMCPDashboardEnabled = usePHFeatureFlag({ key: "new-mcp-dashboard-flow", defaultValue: { isEnabled: false, payload: {} } });
  return {
    navMain: [
      {
        title: "Dashboard",
        url: "#",
        icon: Home,
        isPreOpen: true,
        items: [
          {
            title: "Getting Started",
            url: "/dashboard",
            isActive: true,
          },
          {
            title: "Playground",
            url: "/playground",
          },
        ],
      },
      {
        title: "Apps",
        url: "#",
        icon: AppWindow,
        isPreOpen: true,
        items: [
          {
            title: "All Apps",
            url: "/apps",
          },
          {
            title: "Integrations",
            url: "/integrations",
          },
          {
            title: "Add a Custom Tool",
            url: "/custom_tools",
          },
        ],
      },
      {
        title: "Connections",
        url: "#",
        icon: UserCheck,
        items: [
          {
            title: "Active Connections",
            url: "/connections",
          },
          // {
          //     title: "Entity/Users",
          //     url: "/users",
          // },
          {
            title: "Active Triggers",
            url: "/active_triggers",
          },
          ...(isNewMCPDashboardEnabled.isEnabled
            ? [
                {
                  title: "Active MCP Servers",
                  url: "/mcp_servers",
                },
              ]
            : []),
        ],
      },
      {
        title: "Debug",
        url: "#",
        icon: Bug,
        items: [
          {
            title: "Request Logs",
            url: "/logs",
          },
          {
            title: "Triggers Logs",
            url: "/trigger_logs",
          },
        ],
      },
    ],
    bottomNav: [
      {
        title: "Docs & SDK",
        url: "https://docs.composio.dev",
        icon: BookIcon,
        openInNewTab: true,
      },
      {
        title: "Settings",
        url: "/developers",
        icon: Settings2,
        items: [
          {
            title: "Project Settings",
            url: "/developers",
          },
          {
            title: "Event and Triggers",
            url: "/triggers",
          },
        ],
      },
    ],
  };
};

export const Sidebar = React.memo(({ leavePadding }: { leavePadding?: boolean }) => {
  const { navMain, bottomNav } = useMenu();
  return (
    <SidebarProvider>
      <SidebarDS collapsible="icon" className={`!w-[268px] ${leavePadding ? "!pt-[32px]" : ""}`}>
        <SidebarHeader>
          <ProjectSwitcher />
        </SidebarHeader>
        <SidebarContent className="mt-1">
          <NavMain items={navMain} label="Platform" />
        </SidebarContent>
        <SidebarFooter>
          {/* <OnboardingFlow /> */}
          <NavMain items={bottomNav} />
          <SidebarSeparator />
          <NavUser />
        </SidebarFooter>
        <SidebarRail />
      </SidebarDS>
    </SidebarProvider>
  );
});