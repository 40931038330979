import { getCookie } from "~/utils/cookie";

const CryptoJS = require("crypto-js");

export function getGravatarUrl(email?: string) {
  const emailFromCookie = email || getCookie("composio_email");

  // if (!emailFromCookie) return "";
  const sha256 = CryptoJS.SHA256(emailFromCookie);
  const hash = CryptoJS.enc.Hex.stringify(sha256);

  return `https://www.gravatar.com/avatar/${hash}?s=200&d=retro`;
}
