import { useState } from "react";
import { useRouter } from "next/router";
import { clsx } from "clsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "design-system/ui/atoms/dropdown/dropdown-menu";
import { SquareLogo } from "design-system/ui/atoms/logo";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "design-system/ui/molecules/sidebar/ui/sidebar";
import { CheckIcon, ChevronsUpDown, OrbitIcon, Plus } from "lucide-react";
import { z } from "zod";

import { useUser } from "~/dataProcessor/hooks/user";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogSubtitle,
  DialogTitle,
} from "~/design-system/atom/dialog";
import { Input } from "~/design-system/atom/input";
import { Button } from "~/design-system/atom/newButton";
import { useToast } from "~/design-system/atom/use-toast";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { INITIAL_PAGE_AFTER_LOGIN } from "~/utils/global";
import { useProjects } from "../hooks";

export const AddProjectModal = ({
  isOpen,
  setOpen,
  onSuccess: handleSuccess,
  currentProjects,
}: {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
  onSuccess: () => void;
  currentProjects: {
    id: string;
    name: string;
  }[];
}) => {
  const { toast } = useToast();
  const { createProject, refetchProjects } = useProjects();
  const [projectName, setProjectName] = useState("");
  const router = useRouter();

  const handleAddProject = () => {
    const projectNameSchema = z
      .string()
      .min(3, { message: "Project name cannot be shorter than 3 characters" })
      .regex(/^[a-zA-Z0-9_-]+$/, {
        message: "Project name should only contain alphabets, numbers, underscore and hyphen",
      })
      .max(50, { message: "Project name cannot be longer than 50 characters" })
      .refine((name) => !currentProjects.some((project) => project.name === name), {
        message: "Project with this name already exists",
      });

    const validationResult = projectNameSchema.safeParse(projectName);

    if (!validationResult.success) {
      toast({
        title: "Error",
        description: validationResult.error.errors[0]?.message || "Validation error",
        variant: "destructive",
      });
      return;
    }

    createProject.mutate(projectName, {
      onSuccess: (project) => {
        setOpen(false);
        toast({
          title: "Project added",
          description: "Project created successfully. Switching to the new project...",
          variant: "success",
        });
        refetchProjects();
        router.push(INITIAL_PAGE_AFTER_LOGIN);
        // @ts-ignore
        handleSuccess(project.data.id, projectName);
      },
      onError: (error) => {
        // @ts-ignore
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        toast({
          title: "Error",
          description: errorMessage,
        });
      },
    });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(e) => {
        setOpen(e);
        requestAnimationFrame(() => (document.body.style.pointerEvents = ""));
      }}
    >
      <DialogContent
        className={clsx("max-h-[90vh] w-[500px] overflow-hidden rounded-[12px] bg-[#fff]", scrollBarStyle)}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <span className="text-lg font-medium text-gray-900">Add new project</span>
          </DialogTitle>
          <DialogSubtitle>Add a new project to get started</DialogSubtitle>
        </DialogHeader>
        <DialogDescription>
          <div className="flex flex-col gap-2">
            <label htmlFor="project-name" className="text-sm font-medium text-gray-700">
              Project Name
            </label>
            <Input
              id="project-name"
              className="h-[36px] w-[300px] flex-auto rounded-[6px]"
              placeholder="Enter Project name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>
        </DialogDescription>
        <DialogFooter>
          <div className="flex gap-2">
            <Button variant="white" size={40} className="h-[36px]" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={createProject.isLoading || !projectName}
              variant="default"
              size={40}
              className="ml-0 h-[36px] bg-purple-600"
              onClick={handleAddProject}
            >
              Add and Switch
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export function ProjectSwitcher() {
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const { projects, selectedProjectItem, switchProject } = useProjects();
  const { user } = useUser();
  const router = useRouter();

  const plan = user?.client?.plan || "HOBBY";

  return (
    <>
      <SidebarMenu>
        <SidebarMenuItem>
          <div className="ds-flex ds-items-center ds-w-full">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:ds-bg-sidebar-accent data-[state=open]:ds-text-sidebar-accent-foreground"
                >
                  <SquareLogo size={28} className="!ds-min-w-[28px] !ds-min-h-[28px]" monochrome={false} />
                  <div className="ds-flex ds-items-center ds-gap-2 ds-text-[14px] ds-leading-[14px] w-[120px]">
                    <span className="ds-truncate ds-font-400 ds-text-white-500 ds-text-12">/</span>
                    <span className="ds-truncate ds-font-400 ds-ml-2 ds-max-w-[120px]">
                      {selectedProjectItem?.name || ""}
                    </span>
                    <ChevronsUpDown className="ds-h-[16px]" strokeWidth={1.5} />
                  </div>
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="ds-w-[--radix-dropdown-menu-trigger-width] ds-rounded-[12px] !ds-z-50 min-w-[224px]"
                align="start"
                side="bottom"
                sideOffset={0}
              >
                <DropdownMenuLabel className="ds-text-[12px] !ds-text-black-900">Projects</DropdownMenuLabel>
                {projects.map((project) => (
                  <DropdownMenuItem
                    key={project.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      switchProject(project.id);
                    }}
                    className="ds-gap-2 ds-p-2"
                  >
                    {project?.name || ""}
                    {selectedProjectItem?.id === project.id && <CheckIcon className="ds-size-[12px] ds-ml-auto" />}
                  </DropdownMenuItem>
                ))}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="ds-gap-2 ds-p-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsAddProjectModalOpen(true);
                  }}
                >
                  <div className="ds-flex ds-size-[12px] ds-items-center ds-justify-center ds-rounded-[8px] ds-border ds-border-zinc-200 ds-bg-white ds-dark:ds-border-zinc-800 ds-dark:ds-bg-zinc-950">
                    <Plus className="size-4" />
                  </div>
                  <div className="ds-font-medium ds-text-zinc-500 ds-dark:ds-text-zinc-400">Add project</div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <button onClick={() => router.push("/billing")} className="ds-ml-auto">
              <div className="ds-flex ds-bg-white-0 ds-items-center ds-ml-auto ds-gap-4 ds-px-8 ds-rounded-12 ds-h-[24px] ds-border-half ds-bg--50/40 ds-border ds-border-orange-500 ds-text-orange-800 ds-text-11">
                <OrbitIcon className="ds-size-[12px]" />
                {plan}
              </div>
            </button>
          </div>
        </SidebarMenuItem>
      </SidebarMenu>
      {isAddProjectModalOpen && (
        <AddProjectModal
          isOpen={isAddProjectModalOpen}
          currentProjects={projects}
          setOpen={setIsAddProjectModalOpen}
          // @ts-ignore
          onSuccess={(id) => {
            switchProject(id);
          }}
        />
      )}
    </>
  );
}
