import { useRouter } from "next/router";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "design-system/ui/atoms/dropdown/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "design-system/ui/molecules/sidebar/ui/avatar";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "design-system/ui/molecules/sidebar/ui/sidebar";
import { ChevronsUpDown, ContactIcon, KeyIcon, LogOut, Sparkles } from "lucide-react";

import { DISCORD_LINK } from "~/constants/content";
import { useUser } from "~/dataProcessor/hooks/user";
import { openInNewTab } from "~/utils/urlUtils";
import { getGravatarUrl } from "~/utils/user";
import { logoutUserCompletely } from "../../base";

export function NavUser() {
  const { user, userEmail } = useUser();

  const imageUrl = getGravatarUrl(userEmail);

  const router = useRouter();

  const plan = user?.client?.plan;

  const isUpgradeAvailable = plan === "HOBBY";

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:ds-bg-sidebar-accent data-[state=open]:ds-text-sidebar-accent-foreground px-4"
            >
              <Avatar className="ds-flex ds-items-center ds-justify-center  ds-rounded-[8px] ds-border-[.5px] ds-border-white-200 size-[32px]">
                <AvatarImage src={imageUrl} alt={user?.client?.name} />
                <AvatarFallback className="rounded-[8px]">{user?.client?.name?.charAt(0)}</AvatarFallback>
              </Avatar>
              <div className="ds-grid ds-flex-1 ds-text-left ds-text-13 ds-leading-tight">
                <span className="ds-truncate ds-font-500 ds-text-13">{user?.client?.name}</span>
                <span className="ds-truncate ds-text-11">{userEmail}</span>
              </div>
              <ChevronsUpDown className="ds-ml-auto ds-size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="ds-w-[240px] min-w-56 rounded-lg" side={"right"} align="end" sideOffset={0}>
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="ds-flex ds-items-center ds-gap-8 ds-py-4 ds-px-8 ds-text-left ds-text-12 ds-text-black-1000">
                <Avatar className="ds-size-[28px] ds-rounded-[8px] ds-border-[.1px] ds-border-white-200">
                  <AvatarImage src={imageUrl} alt={user?.client?.name} />
                </Avatar>
                <div className="ds-grid ds-flex-1 ds-ml-2 ds-text-left ds-text-13 ds-leading-tight">
                  <span className="ds-truncate ds-font-500">{user?.client?.name}</span>
                  <span className="ds-truncate ds-text-11">{user?.client?.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem
                onClick={() => {
                  router.push("/billing");
                }}
              >
                <Sparkles className="ds-h-[16px] ds-w-[16px] ds-text-blue-500" />
                {isUpgradeAvailable ? "Upgrade to Pro" : "Billing"}
              </DropdownMenuItem>
            </DropdownMenuGroup>

            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem
                onClick={() => {
                  router.push("/developers");
                }}
              >
                <KeyIcon className="ds-h-[16px] ds-w-[16px]" />
                API Key
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  openInNewTab(DISCORD_LINK);
                }}
              >
                <ContactIcon className="ds-h-[16px] ds-w-[16px]" />
                Contact us on Discord
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => {
                logoutUserCompletely();
              }}
            >
              <LogOut className="ds-h-[16px] ds-w-[16px]" />
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
