import type { AppType } from "next/app";
import { Suspense, useEffect, useState } from "react";
import Head from "next/head";
import Link from "next/link";
import Script from "next/script";
import { QueryClientProvider } from "@tanstack/react-query";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { AlertTriangleIcon, MessageCircleIcon } from "lucide-react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { bootstrap } from "~/bootstap";
import { useAppInfo, useAppRouterAnalytics, useCliKey } from "~/dataProcessor/hooks/app";
import { useUser } from "~/dataProcessor/hooks/user";
import { Toaster } from "~/design-system/atom/toaster";
import { useTriggerDisabledBanner } from "~/hooks/query/triggers";
import { QuerySyncComponent } from "~/hooks/utils/queryParams";
import { queryClient } from "~/queryClient";
import { mobileModeCSS } from "~/styles/emotions/global";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { FullScreenLoader } from "~/ui/components/base/FullScreenLoader";
import { MobileNotAvailableBanner } from "~/ui/components/base/not_available_on_mobile";
import { SidebarLayoutWrapper, SSRWrapper } from "~/ui/layout/base";
import { setThemeHTMLFunction } from "~/utils/theme_utils";

import "../styles/github.css";
import "../styles/globals.css";

bootstrap();

const TriggerDisabledBanner = () => {
  const { showTriggerDisabledBanner } = useTriggerDisabledBanner();
  return (
    <div
      className={`ds-bg-white-0 ds-border-white-300 ds-text-black-800 fixed z-[20] w-full border-b px-4 py-2 text-[13px]  transition-all ${showTriggerDisabledBanner ? "block" : "hidden"}`}
    >
      <div className="flex items-center justify-center">
        <AlertTriangleIcon className="ds-text-red-600 mr-2 h-3 w-4" />
        <span>
          Your triggers are currently disabled. To continue receiving events, please re-enable them
          <Link href="/triggers" className="ds-text-blue-800 ds-underline ml-1 underline">
            here
          </Link>
        </span>
      </div>
    </div>
  );
};

const PlainChat = () => {
  const { userEmail, user } = useUser();
  const [plainHash, setPlainHash] = useState<string | null>(null);
  useEffect(() => {
    fetch("/api/plain", {
      method: "POST",
      body: JSON.stringify({ email: userEmail }),
    })
      .then((res) => res.json())
      .then((data) => setPlainHash(data.hash));
    return () => {
      setPlainHash(null);
    };
  }, [userEmail]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = false;
    script.onload = function () {
      (window as any)?.Plain.init({
        appId: "liveChatApp_01JP6DN9ZZSS56W2MW5B7GV8QF",
        hideLauncher: true,
        links: [
          {
            icon: "book",
            text: "View our docs",
            url: "https://docs.composio.dev/getting-started/welcome",
          },
          {
            icon: "discord",
            text: "Join our Discord",
            url: "https://dub.composio.dev/discord",
          },
        ],
        hideBranding: true,
        theme: "dark",
        style: {
          brandColor: "#076CDF",
          brandBackgroundColor: "#076CDF",
          launcherBackgroundColor: "#FFFFFF",
          launcherIconColor: "#076CDF",
        },
        chatButtons: [
          // API / SDK Issues Button
          {
            icon: "bug",
            text: "API / SDK Issues",
            form: {
              fields: [
                {
                  type: "dropdown",
                  placeholder: "Select Issue Type",
                  options: [
                    {
                      icon: "error",
                      text: "API Issue",
                      threadDetails: {
                        labelTypeIds: ["lt_01JPPWDM0DNWQ3TZ4P579BE0JZ"],
                      },
                    },
                    {
                      icon: "bug",
                      text: "JavaScript SDK",
                      threadDetails: {
                        labelTypeIds: ["lt_01JPVXQF4HBBA7VNSCMJHTQSNE"],
                      },
                    },
                    {
                      icon: "bug",
                      text: "Python SDK",
                      threadDetails: {
                        labelTypeIds: ["lt_01JPVXQANCFAYQ8R7BPSW7XP6Y"],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            icon: "integration",
            text: "Integration / Tool Issues",
            form: {
              fields: [
                {
                  type: "dropdown",
                  placeholder: "Select Issue Type",
                  options: [
                    {
                      icon: "integration",
                      text: "Tool Authentication Not Working",
                      threadDetails: {
                        labelTypeIds: ["lt_01JQ3ZVABTEA7ZW6BR4RPZ2VV6"],
                      },
                    },
                    {
                      icon: "integration",
                      text: "Action Not Working",
                      threadDetails: {
                        labelTypeIds: ["lt_01JQ3ZT8G3QSNZ2T7MBFWB9WMK"],
                      },
                    },
                    {
                      icon: "integration",
                      text: "Trigger Not Working",
                      threadDetails: {
                        labelTypeIds: ["lt_01JQ3ZTXTS2997K038M5EPQ0HC"],
                      },
                    },
                    {
                      icon: "bulb",
                      text: "Request New Action/Trigger",
                      threadDetails: {
                        labelTypeIds: ["lt_01JQ3ZWVCJH4XHM895JCM1FJ4Q"],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            icon: "chat",
            text: "General Questions",
            form: {
              fields: [
                {
                  type: "dropdown",
                  placeholder: "Select Topic",
                  options: [
                    {
                      icon: "book",
                      text: "Documentation Help",
                      threadDetails: {
                        labelTypeIds: ["lt_01JP6PBF53YD6EXC2TNEW0XES4"],
                      },
                    },
                    {
                      icon: "send",
                      text: "Pricing Information",
                      threadDetails: {
                        labelTypeIds: ["lt_01JQ401BG3RPK4WT0187R44P6P"],
                      },
                    },
                    {
                      icon: "support",
                      text: "Security Questions",
                      threadDetails: {
                        labelTypeIds: ["lt_01JQ401V3WA4M50JKNZJMK60MV"],
                      },
                    },
                    {
                      icon: "chat",
                      text: "Other Question",
                      threadDetails: {
                        labelTypeIds: ["lt_01JP21C6MM2JCTX1C5RME57FAJ"], // MCP label for "Other"
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      });
    };
    script.src = "https://chat.cdn-plain.com/index.js";
    document.getElementsByTagName("head")[0]?.appendChild(script);
  }, []);

  useEffect(() => {
    if (userEmail && plainHash && plainHash.length > 0) {
      (window as any)?.Plain?.setCustomerDetails({
        email: userEmail,
        fullName: user?.client.name,
        emailHash: plainHash,
      });
    }
  }, [userEmail, plainHash]);

  return (
    <div className="fixed bottom-4 right-4 z-[9999]">
      <button
        className="ds-bg-blue-500 hover:bg-primary-dark flex h-12 w-12 items-center justify-center rounded-full text-white shadow-lg transition-colors"
        onClick={() => {
          (window as any)?.Plain?.open();
        }}
      >
        <MessageCircleIcon size={18} />
      </button>
    </div>
  );
};

interface AppContentProps {
  Component: any;
  pageProps: any;
  isNonSidebarPage: boolean;
}

const AppContent: React.FC<AppContentProps> = ({ Component, pageProps, isNonSidebarPage }) => {
  const { userEmail, user } = useUser();
  // const ldClient = useLDClient();
  // useEffect(() => {
  //   if (ldClient && userEmail) {
  //     ldClient.identify({
  //       kind: "user",
  //       key: userEmail,
  //       name: user?.client.name || "User",
  //       email: userEmail,
  //     });
  //   }
  // }, [ldClient, userEmail, user]);

  return (
    <div className="flex flex-col">
      <TriggerDisabledBanner />
      <div className="flex-1">
        <QuerySyncComponent />
        <SidebarLayoutWrapper isNonSidebarPage={isNonSidebarPage}>
          <Component {...pageProps} />
        </SidebarLayoutWrapper>
        <div id="login-prompt"></div>
        <div id="modal-root"></div>
        <div id="portal-root"></div>
        <PlainChat />
      </div>
    </div>
  );
};

const MyApp: AppType = ({ Component, pageProps }) => {
  const { isRootPath, isMobileMode, isSEOPath, isNonSidebarPage } = useAppInfo();
  useCliKey();
  useAppRouterAnalytics();

  return (
    <SSRWrapper isSEOPath={isSEOPath}>
      <Head>
        <CommonHead isRootPath={isRootPath} isSEOPath={isSEOPath} />
      </Head>
      {isMobileMode && !isSEOPath && (
        <div className={mobileModeCSS}>
          <MobileNotAvailableBanner showLoader />
        </div>
      )}
      <ErrorBoundary fallback={<div>Error loading component</div>}>
        <Suspense fallback={<FullScreenLoader showLoader />}>
          <PostHogProvider client={posthog}>
            <QueryClientProvider client={queryClient}>
              <LDProvider
                clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID || ""}
                deferInitialization={false}
              >
                <AppContent Component={Component} pageProps={pageProps} isNonSidebarPage={isNonSidebarPage} />
              </LDProvider>
              <Toaster />
            </QueryClientProvider>
            <AppLevelScripts />
          </PostHogProvider>
        </Suspense>
      </ErrorBoundary>
    </SSRWrapper>
  );
};

const CommonHead = ({ isRootPath, isSEOPath }: { isRootPath: boolean; isSEOPath: boolean }) => (
  <>
    <title>Composio.dev</title>
    <meta name="theme-color" content="#000000" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    {!isRootPath && !isSEOPath && <meta name="robots" content="noindex" />}
    <script
      dangerouslySetInnerHTML={{
        __html: setThemeHTMLFunction,
      }}
    />
  </>
);

const AppLevelScripts = () => {
  const isDev = process.env.NODE_ENV === "development";
  if (isDev) {
    return null;
  }
  return (
    <>
      <Script
        defer
        data-domain="composio.dev"
        data-api="https://pa.composio.dev/api/event"
        src="https://pa.composio.dev/js/script.tagged-events.js"
      />
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-CWE0CCJVF2" crossOrigin="anonymous" />
    </>
  );
};

export default MyApp;
