import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { css } from "@emotion/css";
import clsx from "clsx";
import { Button as NewButton } from "design-system/ui/atoms/button";
import { ChevronRightIcon, HelpCircleIcon, KeyIcon } from "lucide-react";

import { logoutUser } from "~/dataProcessor/api/api";
import { useToast } from "~/design-system/atom/use-toast";
import { useTriggerDisabledBanner } from "~/hooks/query/triggers";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { eraseCookie, setCookie } from "~/utils/cookie";
import { storage } from "~/utils/localStorage";
import { FeatureFlagBar } from "./feature_flags";

export const MainContentTopBar = ({
  header,
  headerLinks,
}: {
  header?: string | React.ReactNode;
  headerLinks?: {
    link: string;
    text: string;
    icon: React.ReactNode;
  }[];
}) => {
  const router = useRouter();
  const { toast } = useToast();
  return (
    <div
      className={clsx(
        "ds-border-b-white-500 sticky top-0 z-[100] flex h-[40px] items-center justify-between  border-b-[.5px] bg-[#fff] px-3 pl-4",
      )}
    >
      <div className="ds-flex ds-flex-1 ds-items-center ds-text-black-900 ds-font-text ds-text-12  w-full ">
        <IconBadge icon="🧰" />
        <Link href="/" className=" underline-offset-4 hover:underline">
          Home
        </Link>
        {!!headerLinks?.length ? (
          <div className="flex items-center">
            {headerLinks.map((link) => (
              <div key={link.text} className="flex items-center gap-1">
                <ChevronRightIcon
                  height={12}
                  width={12}
                  strokeWidth={1.2}
                  className="ml-[2px] mr-[0px]"
                  color="#2c2c2c80"
                />
                <Link href={link.link}>
                  <div className="flex items-center gap-1 hover:underline">
                    {link.icon}
                    <span>{link.text}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : !!header ? (
          <>
            <ChevronRightIcon
              height={12}
              width={12}
              strokeWidth={1.2}
              className="mx-[2px] mr-[3px]"
              color="#2c2c2c80"
            />
            <span>{header}</span>
          </>
        ) : null}
      </div>
      <div className="ds-gap-6 flex items-center">
        <NewButton
          variant="secondary_outline"
          size="28"
          className="ds-px-6 ds-pt-0"
          onClick={() => {
            if (window?.Plain) {
              window?.Plain?.open();
            } else {
              toast({
                title: "Plain not found. Please contact support at support@composio.dev",
                variant: "destructive",
              });
            }
          }}
        >
          <HelpCircleIcon className="mr-[4px] h-3 w-3 text-[#000]" />
          Support
        </NewButton>
        <NewButton
          variant="secondary"
          size="28"
          onClick={() => {
            router.push("/developers");
          }}
        >
          <KeyIcon className="h-3 w-3 text-[#fff]" /> API Key
        </NewButton>
      </div>
    </div>
  );
};

export const IconBadge = ({ icon }: { icon: string }) => {
  return (
    <div
      aria-hidden="true"
      className={css`
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        word-break: keep-all;
        font-family: var(--font-emoji);
        width: 20px;
        height: 20px;
        background-color: rgba(90, 92, 95, 0.02);
        margin-right: 8px;
        padding: 3px;
        font-size: 9px !important;
        border: 0.5px solid #8f8f8f6b;
        border-radius: 6px;
      `}
      data-type="emoji"
    >
      {icon}
    </div>
  );
};

export const logoutUserCompletely = () => {
  const { location } = window;
  const { hostname, protocol } = location;

  const logoutPromise = logoutUser();

  // Set a timeout to force logout after 2 seconds
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error("Logout timeout")), 2000);
  });

  // Race between normal logout and timeout
  Promise.race([logoutPromise, timeoutPromise]).finally(() => {
    setCookie("isLoggedIn", "false", 0);
    eraseCookie("composio_analytics_id");
    eraseCookie("composio_email");
    storage.clearAll();

    const logoutURLs = {
      localhost: "/",
      "qa.composio.dev": "https://qa.composio.dev/logout",
      "composio.dev": "https://composio.dev/logout",
    };

    window.location.href = logoutURLs[hostname as keyof typeof logoutURLs] || `${protocol}//${hostname}`;
  });
};

export const RightSideContent = ({
  header,
  headerLinks,
  children,
  showTopBar = true,
  className,
}: {
  header?: string | React.ReactNode;
  children: React.ReactNode;
  showTopBar?: boolean;
  className?: string;
  headerLinks?: {
    link: string;
    text: string;
    icon: React.ReactNode;
  }[];
}) => {
  // if the route is /logs then keep overflowy hidden
  const { pathname } = useRouter();
  const isLogs = pathname.includes("/logs");

  const { showTriggerDisabledBanner } = useTriggerDisabledBanner();
  return (
    <div
      className={clsx(
        " ml-[268px] overflow-x-hidden",
        scrollBarStyle,
        css`
          width: calc(100vw - 272px);
          overflow-y: ${isLogs ? "hidden" : "auto"};
          background: #fff;
          margin-top: 8px;
          z-index: 40;
          position: fixed;
          height: calc(100vh - 6px);
          top: ${showTriggerDisabledBanner ? "32px" : "0px"};
          left: 0px;

          border-left: 1px solid #ebebeb;
          border-top: 1px solid #ebebeb;

          border-left: 0.5px solid #d1cfcf;
          border-top: 0.5px solid #d1cfcf;

          border-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        `,
        className,
      )}
      id="scroll-box"
    >
      <FeatureFlagBar />
      {showTopBar && <MainContentTopBar header={header} headerLinks={headerLinks} />}
      {children}
    </div>
  );
};
