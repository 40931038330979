import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { css } from "@emotion/css";
import { cva } from "class-variance-authority";
import clsx from "clsx";

import { borderAndShadowCSS } from "~/ui/constants/style/common";
import { cn } from "~/utils/cn";

const inputVariaant = cva(
  "block w-full outline outline-[.5px] outline-grey-200 font-[500] bg-[white] text-black-100 bg-[white] pr-3 text-[13px] leading-none placeholder-[#6E6E6E] !focus:outline-black-400 !focus:outline-[1px] focus:bg-[#ba64ff0a] ",
  {
    variants: {
      variant: {
        default: "",
        disabled: "",
      },
      size: {
        default: "py-[11px] rounded-[12px] text-[14px]",
        40: "py-[7px] rounded-[12px] text-[13px]",
        36: "py-[5px] rounded-[12px] text-[13px]",
        32: "py-[3px] pt-[4px] rounded-[12px] text-[13px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  icon?: React.ReactNode;
  onEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  rightIcon?: React.ReactNode;
  parentClassName?: string;
} & VariantProps<typeof inputVariaant>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onEnterPress, icon, size, rightIcon, parentClassName, ...props }, ref) => {
    const handleKeyUp = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && onEnterPress) onEnterPress(event);
      },
      [onEnterPress],
    );

    return (
      <div className={clsx("relative", parentClassName)}>
        {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">{icon}</div>}
        <input
          type={type}
          onKeyUp={handleKeyUp}
          className={clsx(
            css`
              border-width: 0px !important;
              :focus {
                background-color: rgba(126, 181, 240, 0.11) !important;
                outline-width: 1px !important;
                outline-offset: 0px !important;
                outline-color: hsla(212, 85%, 53%, 1) !important;
              }
            `,
            cn(
              inputVariaant({
                className,
                size,
              }),
            ),
            borderAndShadowCSS,
            {
              "pl-[32px]": icon,
              "pl-[12px]": !icon,
              ...(props.disabled && {
                "cursor-not-allowed": true,
                "bg-[#f6f6f6]": true,
              }),
            },
          )}
          ref={ref}
          disabled={props.disabled}
          {...props}
        />
        {rightIcon && <div className="absolute right-3 top-1/2 -translate-y-1/2">{rightIcon}</div>}
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
